export const charges = [
  {
    label: 'Inclus dans le prix : ',
    text:
      "Le prix comprend l'électricité à hauteur de 8 kwh / jour. Prix du kwh supplémentaire: 0,20 €",
  },

  { label: 'Montant de la caution : ', text: '300 €' },
  { label: 'Service ménage : ', text: '60 € (sur demande)' },
  {
    label: 'Draps : ',
    text: '8 € / lit  (sur demande)',
  },
  {
    label: 'Location du linge de toilette : ',
    text: '5 € / personne (sur demande)',
  },
  {
    label: 'Location du linge de maison : ',
    text: '2 € / personne (sur demande)',
  },
  {
    label: 'Chauffage : ',
    text:
      "Le prix du chauffage n'est pas inclus dans le prix. La 1ère flambée dans la cheminée est offerte, le prix du stère supplémentaire : 60€",
  },
];
