import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GalleryWrapper,
  Thumbnail,
  ThumbnailContainer,
} from '../styled/gallery';
import FsLightbox from 'fslightbox-react';
import text from 'config/content/gallery.yml';

const Gallery = ({ thumbnails, images, alt = '' }) => {
  const [toggler, setToggler] = useState(false);
  const [sourceIndex, setSourceIndex] = useState(0);

  function openLightboxOnSource(sourceIndex) {
    setToggler(!toggler);
    setSourceIndex(sourceIndex);
  }
  return (
    <>
      <GalleryWrapper>
        {thumbnails.map((thumbnail, index) => {
          const thumbnailSrc = thumbnail.node.childImageSharp.fluid.src;
          return (
            <ThumbnailContainer
              key={thumbnailSrc}
              onClick={() => openLightboxOnSource(index)}
            >
              <Thumbnail>
                <img
                  src={thumbnailSrc}
                  alt={`Photo du gite ${alt}, à Montferrier dans l'Ariège, ${thumbnail.node.name
                    .replace(/_/g, ' ')
                    .replace('.jpg', '')}`}
                />
              </Thumbnail>
            </ThumbnailContainer>
          );
        })}
      </GalleryWrapper>
      <FsLightbox
        toggler={toggler}
        type="image"
        sourceIndex={sourceIndex}
        sources={images.map(image => image.node.childImageSharp.fluid.src)}
        captions={images.map(image => (
          <div
            key={image.node.name}
            style={{
              padding: '0.3rem 0.8rem',
              backgroundColor: 'rgba(0,0,0,0.6)',
              borderRadius: '0.25rem',
            }}
          >
            {text.captions[image.node.name]}
          </div>
        ))}
      />
    </>
  );
};

Gallery.propTypes = {
  alt: PropTypes.string,
};

export default Gallery;
