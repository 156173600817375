export const prices = [
  {
    name: 'Basse saison',
    dates: [
      '09/01 au 05/02',
      '06/03 au 09/04',
      '08/05 au 28/05',
      '02/10 au 17/12',
    ],
    price: '530',
  },
  {
    name: 'Hiver',
    dates: ['06/02 au 05/03', '18/12/21 au 07/01/2022'],
    price: '640',
  },
  {
    name: 'Moyenne saison',
    dates: ['10/04 au 07/05', '29/05 au 02/07', '28/08 au 01/10'],
    price: '540',
  },
  {
    name: 'Haute saison',
    dates: ['03/07 au 16/07', '21/08 au 27/08'],
    price: '670',
  },
  {
    name: 'Très haute saison',
    dates: ['17/07 au 20/08'],
    price: '680',
  },
];
