import React from 'react';
import PropTypes from 'prop-types';
import Capacity from '../../Capacity';
import Area from '../../Area';
import Bedroom from '../../Bedroom';
import noPet from '../../../images/no-pet.svg';
import { MainInfosItems } from '../styled/gites';

const MainInfos = ({ capacity, bedrooms, area }) => {
  return (
    <div>
      <MainInfosItems>
        <li className="item">
          <Capacity number={capacity} />
          <span className="text">{capacity} personnes</span>
        </li>
        <li className="item">
          <Bedroom number={bedrooms} />
          <span className="text">{bedrooms} chambres</span>
        </li>

        <li className="item">
          <Area area={area} />
        </li>
      </MainInfosItems>
    </div>
  );
};

MainInfos.propTypes = {
  capacity: PropTypes.number.isRequired,
  bedrooms: PropTypes.number.isRequired,
  area: PropTypes.string.isRequired,
};

export default MainInfos;
