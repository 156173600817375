import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import Layout from 'components/Layout';
import { PageTitle, H2, H3 } from 'components/shared/styled/headings';
import Epi from 'components/Epi';
import { Content } from 'components/shared/styled/layout';
import {
  SecondaryInfos,
  DescriptionList,
} from 'components/shared/styled/gites';
import Gallery from 'components/shared/Gallery';
import MainInfos from 'components/shared/MainInfos';
import { PricesTable, Charges } from 'components/shared/styled/prices';
import noPet from 'images/no-pet.svg';
import noSmoking from 'images/no-smoking.svg';
import { prices as pricesData } from 'config/grange/prices';
import { charges as chargesData } from 'config/grange/charges';

const PageGrangeNeuve = ({ data }) => {
  const thumbnails = [
    ...data.thumbnails.edges.sort((a, b) =>
      a.node.name.localeCompare(b.node.name)
    ),
  ];

  const images = [
    ...data.images.edges.sort((a, b) => a.node.name.localeCompare(b.node.name)),
  ];

  const mainPhoto = images.find(
    image => image.node.name === 'exterieur_facade_sud'
  );

  return (
    <Layout>
      <Helmet>
        <title>La Grange Neuve - Gîtes en Pyrénées Cathares</title>
        <meta
          name="description"
          content="Gîte de charme rénové dans le style du pays où l'on retrouve le charme
          intemporel de la pierre et du bois."
        />
      </Helmet>
      <Content style={{ paddingTop: '3rem' }}>
        <PageTitle>La Grange Neuve</PageTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <Epi number={3} width="1.2rem" keyNamespace="grange" />
        </div>
        <p>
          Gîte de charme rénové dans le style du pays où l'on retrouve le charme
          intemporel de la pierre et du bois. Le grand salon, très convivial et
          intime est parfait pour savourer une soirée autour de la cheminée
          après une journée de ski par exemple (station de ski à 15 kms).
        </p>
        <p>
          Il est un autre plaisir, celui de prendre le petit-déjeuner sur la
          terrasse avec le château de Montségur en toile de fond. Un sentier de
          montagne part du gîte et vous amènera à la découverte de ce site
          remarquable.
        </p>
        <Img
          fluid={mainPhoto.node.childImageSharp.fluid}
          alt="Photo du gîte la Grange Neuve"
          style={{
            display: 'block',
            maxWidth: '100%',
            border: '16px solid #fff',
            boxShadow: '0 2px 4px rgba(50,50,50,0.3)',
          }}
        />
        <MainInfos capacity={6} bedrooms={3} area="120" />
        <H2 className="gite-subtitle">Le gîte</H2>
        <p style={{ marginTop: 0 }}>
          Au rez-de-chaussée vous trouverez une chambre avec 2 lits 1 place, une
          salle d'eau et un WC.
        </p>
        <p>
          A l'étage il y a un séjour et un coin cuisine, 2 chambres (literie
          latex) avec 1 lit en 140 et 1 lit en 160, un lit bébé, une salle d'eau
          avec wc. Un poêle finlandais à bois et à double combustion permet de
          chauffer tout l'étage.
        </p>
        <p>
          Le gîte bénéficie d'un cellier, d'une terrasse, d'un jardin clôturé et
          d'un parking.
        </p>
        <p>
          Les repas peuvent être pris sur la terrasse avec vue sur les
          montagnes.
        </p>
        <SecondaryInfos>
          <section>
            <H3 className="gite-subtitle" style={{ margin: '1rem 0 0' }}>
              Équipements & services
            </H3>
            <DescriptionList>
              <li>TV par Wifi et lecteur DVD</li>
              <li>Téléphone fixe et accès internet illimités</li>
              <li>Service raclette</li>
              <li>Sèche cheveux</li>
              <li>Lave-linge</li>
              <li>Sèche-linge</li>
              <li>Congélateur</li>
              <li>Salon de jardin et transats</li>
              <li>Barbecue</li>
              <li>Draps et linge de maison fournis (avec supplément)</li>
              <li>Équipement bébé</li>
            </DescriptionList>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={noPet}
                title="Animaux interdits"
                alt="Logo animaux interdits"
                style={{ width: '1.5rem', marginRight: '0.3rem' }}
              />
              <span>Les animaux ne sont pas autorisés.</span>
            </p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={noSmoking}
                title="Non fumeur"
                alt="Logo non fumeur"
                style={{ width: '1.5rem', marginRight: '0.3rem' }}
              />
              <span>Non fumeur</span>
            </p>
          </section>
          <aside style={{ flexGrow: 1, maxWidth: '450px' }}>
            <iframe
              style={{
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                border: 'none',
              }}
              height="550"
              width="100%"
              src="https://widget.itea.fr/widget.php?key=sgh64-fg&numgite=09G11174&widget-itea=resa&nbmois-ligne=1"
            ></iframe>
          </aside>
        </SecondaryInfos>
        <H2 className="gite-subtitle">Galerie</H2>
        <Gallery
          thumbnails={thumbnails}
          images={images}
          alt="La Grange Neuve"
        />
        <H2 className="gite-subtitle">Tarifs</H2>
        <PricesTable cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>Saison</th>
              <th>Dates</th>
              <th>Prix pour 7 jours</th>
            </tr>
          </thead>
          <tbody>
            {pricesData.map(season => (
              <tr key={season.name}>
                <td>
                  <div>
                    <strong>{season.name}</strong>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      {season.dates.map(date => (
                        <div key={date}>{date}</div>
                      ))}
                    </div>
                  </div>
                </td>
                <td>{`${season.price} €`}</td>
              </tr>
            ))}
          </tbody>
        </PricesTable>
        <Charges>
          <ul className="list">
            {chargesData.map(({ label, text }) => (
              <li key={label} className="list-item">
                <strong>{label}</strong>
                <span>{text}</span>
              </li>
            ))}
          </ul>
        </Charges>
      </Content>
    </Layout>
  );
};

export const query = graphql`
  query getGrangeData {
    images: allFile(filter: { relativeDirectory: { eq: "grange" } }) {
      edges {
        node {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    thumbnails: allFile(filter: { relativeDirectory: { eq: "grange" } }) {
      edges {
        node {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default PageGrangeNeuve;
