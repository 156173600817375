import styled from '@emotion/styled';

export const PricesTable = styled.table`
  width: 100%;
  margin-bottom: ${props => props.theme.spaces.lg};
  border-collapse: collapse;
  background: #fff;
  font-size: ${props => props.theme.fonts.smallSize};
  box-shadow: ${props => props.theme.shadows.boxShadow};
  text-align: center;
  border-radius: ${props => props.theme.borders.borderRadius};
  overflow: hidden;

  thead tr {
    height: 40px;
    color: #fff;
    background: ${props => props.theme.colors.green};

    th:first-of-type {
      border-right: 1px solid #fff;
    }
  }

  tbody {
    background: #fff;
    border-radius: 2rem;
    td {
      min-height: 40%;
      padding: 0.6rem;
    }
    tr,
    td {
      border: 1px solid #eee;
    }
  }
`;

export const Charges = styled.section`
  margin-bottom: ${props => props.theme.spaces.lg};
  background: #fff;
  font-size: ${props => props.theme.fonts.smallSize};
  border-radius: ${props => props.theme.borders.borderRadius};
  box-shadow: ${props => props.theme.shadows.boxShadow};
  .list {
    padding: ${props => props.theme.spaces.md};
  }
  .list-item {
    list-style: none;
    &:not(:last-of-type) {
      margin-bottom: ${props => props.theme.spaces.sm};
    }
  }
`;
