import styled from '@emotion/styled';

export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ThumbnailContainer = styled.div`
  flex-basis: 48%;
  margin: 0 1% 1%;

  :hover {
    cursor: pointer;
  }
  @media (min-width: 600px) {
    flex-basis: 23%;
  }
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-top: 66%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(50, 50, 50, 0.3);
  border: 4px solid #fff;

  img {
    display: block;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
